@media (min-width: 768px) {

	#table-dashboard-user {

		align-items: center;

		th, td {

			vertical-align: middle;

		}

	}

}

@media (max-width: 767px) {

	#table-dashboard-user {

		display: flex;

		position: relative;

		width: 100%;

		thead {

			display: flex;

			width: 120px;

			tr {

				display: flex;

				flex-direction: column;

				th {

					height: 60px;

					text-align: center;

				}

			}

		}

		tbody {

			display: flex;

			width: calc(100% - 120px);

			overflow: auto;

			tr {

				display: flex;

				flex-direction: column;

				width: inherit;

				td {

					height: 60px;

					border-left: solid 1px #1f3a68;

					text-align: center;

                    padding-left: 10px;
                    padding-right: 10px;

				}

			}

		}

	}

}